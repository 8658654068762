// Translated
// Migrated
<template lang="pug">
.side-menu-dropdown-list.overflow-x-hidden.mt-2.mt-lg-n5
  button.btn.btn-link.submenu-close.d-lg-none.text-white(@click="close(false)")
    fa(icon="times")

  nuxt-link.bg-blue.py-3.submenu-title.text-left.font-weight-bold(
    data-i18n="seeAllCategory"
    :to="categoryLink.url"
    @click="close(true)"
  ) {{ $t('seeAllCategory') }}
    fa.ml-2.h5(icon="arrow-right")

  simplebar.submenu-columns.bg-white(
    data-simplebar-auto-hide="false",
    data-simplebar-scrollbar,
    data-simplebar-scrollbar-min-size="20"
  )
    ul.submenu-column.pr-3
      li(
        v-for="(trip, index) in sortedTrips"
        :key="index"
      )
        template(v-if="translationActive")
          .font-mono.text-truncate.rounded-4.hover-bg-white-blue.hover-font-weight-bold
            span(:data-translation="trip?.tripname_translationkey") {{ trip.tripname }}
            span.ml-1(:data-translation="trip?.url_translationkey") {{ trip.url }}
        template(v-else)
          nuxt-link.text-truncate.rounded-4.hover-bg-white-blue.hover-font-weight-bold(
            :to="trip.url"
            @click="close(true)"
          )
            span {{ trip.tripname }}
</template>

<script>
import { faList, faGlobe } from '@fortawesome/pro-solid-svg-icons'
import { faCalendar } from '@fortawesome/pro-duotone-svg-icons'
import { mapState, mapActions } from 'pinia'

const icons = {
  faList,
  faGlobe,
}

export default defineNuxtComponent({
  props: {
    trips: {
      type: Array,
      required: true,
    },

    categoryLinks: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    titleTranslation: {
      type: String,
      default: null,
    },
  },

  emits: ['close'],

  data () {
    return {
      window: 0,
    }
  },

  computed: {
    ...mapState(useLocaleStore, {
      translationActive: 'fullTranslationSuiteActive',
    }),

    sortedTrips () {
      return [...(this.trips || [])]
        .sort((a, b) => a?.tripname > b?.tripname ? 1 : -1)
    },

    categoryLink () {
      return this.categoryLinks?.[0]
    },
  },

  mounted () {
    window.addEventListener('resize', this.resizeListener)

    this.window = window.innerWidth
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.resizeListener)
  },

  methods: {
    ...mapActions(useMenuStore, ['closeSideMenuActive']),

    resizeListener () {
      if (window.innerWidth === this.windowWidth) {
        return
      }
      this.windowWidth = window.innerWidth
    },

    icon (icon) {
      if (icon === 'calendar') {
        return faCalendar
      }

      const iconClass = 'fa' + icon.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join('')

      return icons[iconClass] || icon
    },

    close (closeMenu = false) {
      if (closeMenu) {
        this.closeSideMenuActive()
      }
      this.$emit('close')
    },
  },
})
</script>

<style lang="scss">
@import "simplebar-vue/dist/simplebar.min.css";
.submenu-column {
  .font-mono span {
    width: 48%;
    overflow: hidden;
    display: inline-block;
    letter-spacing: -2px;
  }
}
</style>
